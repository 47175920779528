.youtube {
    margin: 36px 12px;
    padding: 12px;
    h1 {
        text-transform: uppercase;
        font-size: 26px;
    }

    .youtub-video {
        height: 400px;
    }

    .youtub-form {
        margin: 12px 0;
        select {
            height: 40px;
            width: 100%;
            border: 2px solid #4CAF50; /* Màu viền */
            border-radius: 8px; /* Bo tròn các góc */
            background-color: #f9f9f9; /* Màu nền */
            color: #333; /* Màu chữ */
        }
    }
}