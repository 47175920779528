.contact-destop {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    position: fixed;
    right: 12px;
    bottom: 12px;
    padding: 12px 12px;
    // border: 2px solid #6cb917;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
    border-radius: 8px;
    z-index: 2;

    img {
        width: 30px;
        height: 30px;
    }

    .contact-destop-zalo, .contact-destop-phone , .contact-destop-fb {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 24px;

        div {
            font-size: 14px;
            font-weight: 500;
        }

        a {
            color: #333
        }
    }

    .contact-destop-phone {
        .img-phone-box {
            width: 30px;
            height: 30px;
            background-color: #6cb917;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
    
            img {
                width: 20px;
                height: 20px;
                display: inline-block;
                animation: shake 0.5s ease-in-out infinite;
            }
            @keyframes shake {
                0% { transform: translate(0, 0) rotate(0); }
                10% { transform: translate(-2px, -2px) rotate(-1deg); }
                20% { transform: translate(2px, 2px) rotate(1deg); }
                30% { transform: translate(-2px, 0) rotate(-1deg); }
                40% { transform: translate(2px, -2px) rotate(1deg); }
                50% { transform: translate(0, 2px) rotate(0); }
                60% { transform: translate(-2px, 2px) rotate(-1deg); }
                70% { transform: translate(2px, 0) rotate(1deg); }
                80% { transform: translate(0, -2px) rotate(-1deg); }
                90% { transform: translate(-2px, 2px) rotate(1deg); }
                100% { transform: translate(0, 0) rotate(0); }
              }
        }
    }
    
}