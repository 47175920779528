.reason {
    padding: 12px 12px;
    background-color: #F3F3F3;
    h1 {
        text-transform: uppercase;
    }
    .reason_box {
        display: flex;
        flex-wrap: wrap;
        // gap: 10px;

        .uytin, .giare, .phucvu, .nhanvien {
            padding: 12px;
            flex: 1 1 80px;
            img {
                width: 110px;
                height: 110px;
                margin: -24px 0;
                text-align: center;
            }

            p {
                font-weight: 500;
                font-size: 18px;
                text-align: center;
                margin-top: 6px;
            }

            .reason-des {
                text-align: justify;
            }
        }
    }
}