
.header-mobile {
  z-index: 10000;
  position: relative;
  
    .logo-box {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 6px 0;
    
        .img-box {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: 12px;
            .img-logo {
                width: 60px;
                height: 60px;
                border-radius: 50% !important;
                border: 3px solid rgb(81, 117, 71);
            }
            h1 {
              color: rgb(88, 94, 99);
              border-top-right-radius: 24px;
              border-bottom-right-radius: 24px;
              border: 3px solid rgb(81, 117, 71);
              border-left: none;
              background-color: #F8FFFB;
              padding: 4px;
              font-size: 18px;
              
              font-size: 16px;
              margin: 0;
              margin-left: -6px;
          }
        }
            
        .icon-menu {
            margin: 0 12px;
            background-color: rgb(40, 171, 127);
            border-radius: 6px;
            padding: 6px 10px;
            color: #fff;
            opacity: 1;
            transition: opacity 0.6s ease;
            transition: transform 0.6s ease;
            user-select: none;
        }
        .hide {
            opacity: 0;
            transform: scale(0.8);
        }
    }

    .modal-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
        opacity: 0;
        visibility: hidden;
        transition: opacity 0.3s ease, visibility 0.3s ease;
      }
    
      .modal-overlay.visible {
        opacity: 1;
        visibility: visible;
      }

    .sidebar-container {
        position: relative;
        
      
        .close-icon {
          color: #fff;
          position: absolute;
          top: -56px;
          right: 20px;
          font-size: 30px;
          cursor: pointer;
          border: none; /* Remove border */
          outline: none; /* Remove focus outline */
          user-select: none; /* Prevent text selection */
          -webkit-tap-highlight-color: transparent; /* Remove tap highlight color on mobile */
        }
             
        .close-icon {
          opacity: 0;
        }
      
        .show {
          opacity: 1;
          animation: slideUp 0.6s forwards; /* Slide up animation */
        }

        @keyframes slideUp {
            from {
              top: -36px;
            }
            to {
              top: -56px;
            }
        }
      
        .hide {
          opacity: 0;
        }
      
        .sidebar {
          position: fixed;
          top: 0;
          left: -250px; /* Initially hidden */
          width: 250px;
          height: 100%;
          background: #333;
          color: white;
          transition: left 0.3s ease;
          padding-top: 60px;
          overflow: scroll;
      
          ul {
            text-align: left;
            text-transform: uppercase;
            font-weight: 500;
            list-style-type: none;
            padding: 0;
            margin: 0;
      
            li {
              padding: 15px 20px;
      
              a {
                color: white;
                text-decoration: none;
                display: block;
                transition: background 0.3s ease;
      
                &:hover {
                  background: #575757;
                }
              }

              .submenu-lambanghieu {
                display: none;
                margin-top: 10px;
                margin-left: 10px;
                font-size: 14px;
                font-weight: 400;

                li {
                    padding: 16px;
                    padding-left: 0;
                    border-bottom: 0.5px solid gray;
                }

                > * {
                    &:last-child {
                        padding-bottom: 0px;
                        border-bottom: 0;
                    }
                 }
              }
              .submenu-lambanghieu.visible {
                display: block;
              }

              
            }

            .li-lambanghieu {
                .muiten-box {
                    display: flex;
                    justify-content: space-between;
                    .muiten.visible {
                        animation: xoaymuiten 0.4s forwards;
                    }

                    @keyframes xoaymuiten {
                        from {
                            transform: rotate(0deg);
                        }
                        to {
                            transform: rotate(180deg);
                        }
                    }

                    .muiten.disable {
                        animation: xoaymuiten2 0.4s forwards;
                    }
                    @keyframes xoaymuiten2 {
                        from {
                            transform: rotate(180deg);
                        }
                        to {
                            transform: rotate(0deg);
                        }
                    }
                }
            }

            .li-lambanghieu.visible {
                background-color: #575757;
            }
          }

          .sdt {
            background-color: #565454;
            color: #f9f7f7;
            width: 60%;
            display: flex;
            margin: auto;
            border-radius: 20px;
            padding: 8px;
            box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
            margin-bottom: 12px;
            div {
              display: flex;
              margin: auto;
              font-size: 20px;
              font-weight: 900;
            }
          }
        }
      
        .open {
          left: 0; /* Slide in */
        }
      }
}
