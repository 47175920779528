.about {
    text-align: justify;
    margin: 20px 6%;
    color: #333;
    background: #fff;
    font-family: 'Roboto', sans-serif;
    

    h1 {
        text-align: left;
        text-transform: uppercase;
        color: #241455;
      }

      .about-noibat {
        border-left: 4px solid #241455;
        padding: 6px 4%;
        margin: 10px 0px 10px 0px;
        background: rgb(244, 243, 243);
      }
  }
  
  
  
  
  