.footer {
    background-color: #F3F3F3;
    margin-top: 12px;
    padding: 12px;
    display: flex;
    justify-content: space-between;
    text-align: left;

    @media screen and (max-width: 767px) { 
        flex-direction: column;
    }


    .footer-contact {
        margin-bottom: 12px;
        h3 {
            font-size: 18px;
            font-weight: 500;
            text-transform: uppercase;
        }
        .img-box {
            margin: 16px 0 ;
            img {
                width: 90px;
                height: 90px;
                border-radius: 50% !important;
                margin: -14px 0;
            }
        }

        .footer-contact-icon {
            h4 {
                font-size: 16px;
            }
            div {
                margin-bottom: 8px;
            }
        }
    }

    .footer-social {
        margin-bottom: -22px;
        h3 {
            font-size: 18px;
            font-weight: 500;
            text-transform: uppercase;
        }

        .footer-social-box {
            margin: 12px 0;
            span {
                img {
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                }
            }
        }
        
    }
    
    .footer-cat {
        margin: 0;
        padding: 0;
        h3 {
            font-size: 18px;
            font-weight: 500;
            text-transform: uppercase;
        }

        li {
            margin-bottom: 8px;

            a {
                color: #333;
                &:hover {
                    color: #898686;
                }
            }
        }
    }
}

.footer-designer {
    background-color: rgb(226, 224, 221);
    font-size: 12px;
    padding: 8px 0;

    @media screen and (max-width: 767px) { 
        margin-bottom: 60px;
    }
}