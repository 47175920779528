.form_pass {
    // position: relative;
    // top: 48px;
    // left: 48px;
    color: #333;
    input {
        border: 1px solid #ccc; /* Màu và độ dày của border */
        border-radius: 5px; /* Độ cong của các góc */
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.1); /* Đổ bóng */
        outline: none; /* Loại bỏ viền màu xanh khi input được focus */
        padding: 4px 6px;
    }
    button {
        padding: 4px;
        border: 0.5px solid #333;
        border-radius: 10px;
        margin-left: 6px;
    }
}

.form_tk_box {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #333;
    width: 100%;

    .form_tk {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 800px; /* Giới hạn chiều rộng tối đa để form không quá rộng */
        margin: 12px;

        @media (min-width: 768px) {
            width: 36%;
        }

        .form_tk_row {
            display: flex;
            justify-content: space-between;
            align-items: center; /* Căn giữa các phần tử theo chiều dọc */
            margin-top: 20px;
            padding: 2px;
            text-align: left;

            .form_tk_row_left {
                font-weight: 500;
                flex: 1;
                min-width: 120px; /* Đảm bảo cột bên trái không quá nhỏ */
            }

            .form_tk_row_right {
                flex: 3;

                input {
                    width: 100%; /* Đảm bảo input chiếm toàn bộ không gian */
                    padding: 8px; /* Thêm padding cho input */
                    border: 1px solid #ccc; /* Thay đổi màu viền input */
                    border-radius: 4px; /* Bo góc viền */
                    font-size: 14px; /* Kích thước font cho input */
                    
                    &::placeholder {
                        color: #e6e2e2; /* Màu chữ của placeholder */
                    }
                }

                select {
                    width: 100%; /* Đảm bảo select chiếm toàn bộ không gian */
                    padding: 8px; /* Thêm padding cho select */
                    border: 1px solid #ccc; /* Thay đổi màu viền select */
                    border-radius: 4px; /* Bo góc viền */
                    font-size: 14px; /* Kích thước font cho select */
                }

                .form_tk_input_warm {
                    border: 1px solid #f65c5c !important; /* Màu và độ dày của border khi có lỗi */
                }

                // Định dạng cho giá thành và thông báo
                .price_info {
                    display: flex;
                    align-items: center;
                    position: relative;

                    input {
                        width: 60%; /* Đặt chiều rộng cho input giá thành */
                        margin-right: 8px; /* Khoảng cách giữa các phần tử */
                    }

                    span {
                        width: 40%;
                        text-align: right; /* Căn phải cho đơn vị tiền tệ */
                    }

                    i {
                        font-size: 12px;
                        color: #ccc;
                        margin-top: 6px;
                        position: absolute;
                        top: 100%;
                        left: 0;
                        white-space: nowrap; /* Ngăn cản xuống dòng */
                    }
                }
            }

            .complete {
                display: flex;
                justify-content: left;
                align-items: center;
                .checkbox-box {
                    flex: 1;
                    display: flex;
                    justify-content: left;
                    align-items: center;
                    height: 20px;
                    margin: 0;
                    margin-right: 6px;
                    padding: 0;

                    border: 1px solid red;
                }
                .complete-box {
                    flex: 9;
                    font-size: 14px;
                }
    
            }

            .form_tk_row_right.db {
                input {
                    &.form_tk_input_warm {
                        border: 1px solid #f65c5c !important; /* Định dạng lỗi cho input */
                    }
                }
            }

            // Định dạng cho các nút button
            .form_tk_row button {
                margin: 0 8px; /* Khoảng cách giữa các nút */
                width: auto; /* Chiều rộng tự động */
                font-weight: 700; /* Đặt trọng số font cho nút */
                padding: 8px 16px; /* Thêm padding cho nút */
                border-radius: 4px; /* Bo góc viền nút */
                cursor: pointer; /* Con trỏ tay khi hover */
                border: none; /* Xóa border mặc định */
            }

            .form_tk_row button[type="submit"] {
                background-color: #007bff; /* Màu nền cho nút submit */
                color: #fff; /* Màu chữ cho nút submit */
                
                &:disabled {
                    background-color: #6c757d; /* Màu nền khi nút bị vô hiệu hóa */
                    cursor: not-allowed; /* Con trỏ không được phép khi nút bị vô hiệu hóa */
                }
            }

            .form_tk_row button.variant-secondary {
                background-color: #6c757d; /* Màu nền cho nút secondary */
                color: #fff; /* Màu chữ cho nút secondary */
            }
        }
    }
}


.table_box {
    overflow-x: auto; /* Cho phép cuộn ngang nếu cần thiết */
    
    font-size: 12px;
    width: 100vw;
    height: max-content;
    padding: 12px;
    position: relative;
    z-index: 1;

    @media (min-width: 768px) {
        height: 42%;
        padding: 0 48px;
    }

    /* Đảm bảo bảng không bị tràn ra ngoài container */
    .table_form {
        width: 100%;
        border-collapse: collapse;

        /* Định dạng cho các ô của bảng */
        th, td {
            padding: 8px;
            border: 1px solid #ddd; /* Viền nhẹ cho các ô */
            text-align: left;
            vertical-align: middle;
        }

        /* Định dạng cho tiêu đề bảng */
        th {
            background-color: #f4f4f4; /* Màu nền nhẹ cho tiêu đề */
            text-align: center;
        }

        /* Định dạng cho các loại filter */
        .filter_type, .filter_date , button{
            padding: 4px;
            border: 0.8px solid #bcb7b7;
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2); /* Tạo hiệu ứng đổ bóng */
            border-radius: 10px;
            color: #333;
            width: 100%; /* Đảm bảo rộng đầy đủ */
        }

        /* Định dạng cho nút "viendo" */
        .viendo {
            border: 1px dashed red !important;
            color: red;
        }

        /* Định dạng cho thông báo sao chép */
        .copy_info {
            position: absolute;
            top: 2px;
            left: 2px;
            background-color: #b4a6a6;
            color: #fff;
            font-size: 10px;
            border-radius: 10px;
            padding: 2px 4px;
            display: none; /* Ẩn mặc định, hiển thị khi cần */
        }

        /* Định dạng cho overlay */
        .man_che_phu {
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: rgba(0, 0, 0, 0.3); /* Màu nền mờ để tạo hiệu ứng */
            z-index: 2;
        }

        /* Định dạng cho ô không có xuống dòng */
        .table_td_koxuongdong {
            white-space: nowrap;
        }

        .chua-thanh-toan {
            border: 1px dashed red !important;
            border-radius: 6px;
            padding: 2px;
        }
    }

    /* Định dạng cho spinner */
    .spinner-border {
        color: #ccc;
    }
}

.form_tinh_cong-close {
    background-color: #f5f5f5; /* Nền xám sáng */
   
    position: absolute;
    right: 6px;
    top: -12px;
    font-size: 24px;
    z-index: 10000;;
    margin: 12;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;      

    a {
        color: #d9534f; /* Màu X đỏ */
    }
}





