.procedure {
    margin: 24px 12px;
    .procedure-title {
        h1 {
            text-transform: uppercase;
        }
    }

    .procedure-mobile {
        margin-bottom: 20px;
        .procedure-img {
            display: flex;
            justify-content: center;
            align-items: center;
            .procedure-img-box {
                border-radius: 50%;
                width: 80px;
                height: 80px;
                display: flex;
                justify-content: center;
                align-items: center;

                img {
                    width: 50px;
                    height: 50px;
                }
            }
            
        }

        .procedure-con {
            width: 50%;
            height: 24px;
            border-right: 3px dashed #afabab;
        }

        .procedure-des {
            border: 0.1px solid rgb(201, 198, 198);
            text-align: left;
            box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

            p {
                padding: 12px;
                margin: 0;
                color: #fff;
                font-weight: 500;
                font-size: 16px;
            }

            div {
                padding: 24px ;
                margin: 0;
                text-align: left;
            }
        }
    }


    .procedure-destop {
        display: flex;
        height: 160px;
        .procedure-img {
            flex: 1;
            height: 100%;
            position: relative;

            .procedure-img-box {
                width: 80px;
                height: 80px;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                position: absolute;
                
                z-index: 2;
                background-color: #fff;

                img {
                    width: 50px;
                    height: 50px;
                }
            }
        }

        .procedure-des {
            flex: 1;
            height: 100%;
            display: flex;
            
            .procedure-des-box {
                width: 80%;
                border: 0.1px solid rgb(201, 198, 198);
                text-align: left;
                box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
                p {
                    padding: 12px;
                    margin: 0;
                    color: #fff;
                    font-weight: 500;
                    font-size: 16px;
                }
    
                div {
                    padding: 24px ;
                    margin: 0;
                    text-align: left;
                }
            }
            
        }
    }
}