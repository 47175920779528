.header-destop {
    padding: 0px 12px 12px 0;
    .logo-box {
        display: flex;
        justify-content: center;
        align-items: center;
        
        .img-box {
            display: flex;
            align-items: center;
            margin-top: 4px;
            .img-logo {
                width: 120px;
                height: 120px;
                border-radius: 50% !important;
                border: 4px solid rgb(81, 117, 71);
            }
            h1 {
                color: rgb(88, 94, 99);;
                border-top-right-radius: 24px;
                border-bottom-right-radius: 24px;
                border: 4px solid rgb(81, 117, 71);
                border-left: none;
                background-color: #F8FFFB;
                padding: 10px;
                margin-left: -6px;
                font-size: 18px;
            }
        }
    }

    .menu-destop {
        display: flex;
        justify-content: space-evenly;
        font-weight: 500;
        font-size: 16px;
        text-transform: uppercase;
        z-index: 100;
        position: relative;
        
        a {
            color: #333;
            &:hover {
                color: rgb(172, 120, 22);
            }
        }

        .menu-trangchu, .menu-lambh, .menu-lienhe a {
            font-weight: 700;
            color: #fff;
            &:hover {
                color: rgb(172, 120, 22);
            }
        }

        div {
            cursor: pointer;
        }

        .li-lambanghieu {
            position: relative;
            .muiten {
                margin-left: 6px;
                display: inline-block;
                color: #fff;
                &:hover {
                    color: rgb(172, 120, 22);
                }
            }
            .muiten.visible {
                animation: xoaymuiten 0.4s forwards;
            }
            @keyframes xoaymuiten {
                from {
                    transform: rotate(0deg);
                }
                to {
                    transform: rotate(180deg);
                }
            }

            .muiten.disable {
                animation: xoaymuiten2 0.4s forwards;
            }
            @keyframes xoaymuiten2 {
                from {
                    transform: rotate(180deg);
                }
                to {
                    transform: rotate(0deg);
                }
            }

            .submenu-lambanghieu {
                display: none;
                position: absolute;
                top: 36px;
                left: 0;
                background-color:#fff;
                text-align: left;
                padding: 0px 12px;
                width: max-content;
                border: 0.5px solid gray;
                border-radius: 6px;
               

                li {
                    list-style: none;
                    padding: 0;
                    margin: 0;
                    padding: 12px 0;
                    border-bottom: 0.2px solid gray;
                    color: #333 !important;
                }
                > * {
                    &:last-child {
                        border-bottom: 0;
                    }
                 }
              }
              .submenu-lambanghieu.visible {
                display: block;
              }
        }
    }
    
}