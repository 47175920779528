.content {
    margin: 12px 0;
    .bh_box {
        margin: 12px;
        display: flex;
        justify-content: space-between;
        border: 2px solid rgb(218, 216, 216);
        border-radius: 6px;
        height: 160px;

        .bh_img {
            height: 100%;
            img {
                width: 100%;
                height: 100%;
                border-top-right-radius: 6px;
                border-bottom-right-radius: 6px;
            }
            flex: 1;
        }

        .bh_des_box {
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            padding: 0 10px;
            text-align: left;
            margin: 0;

            @media screen and (min-width: 768px) { 
                margin-left: 12px;
            }
            

            .bh_name {
                font-weight: 500;
                text-transform: uppercase;  

                @media screen and (min-width: 768px) { 
                    font-weight: 500;
                }        
            }

            .bh_des {
                font-size: 14px;
                @media screen and (max-width: 767px) { 
                    line-height: 1.2;
                }
            }

            .bh_button {
                    width: max-content;
                    background-color: #0B5ED7;
                    color: #fff;
                    border: 0.5px solid #fff;
                    padding: 8px;
                    border-radius: 6px;
                    text-decoration: none;
                    transition: transform 0.2s ease;

                    a {
                        color: #fff;
                    }

                    &:hover {
                        transform: scale(0.95);
                    }
                
                
            }
        }
        
    }

    .qc_other {
        border: 0.5px solid rgb(218, 216, 216);
        border-radius: 6px;
        margin: 36px 12px;
        position: relative;
        display: flex;
        justify-content: center;
        background-color: #F3F3F3;
        padding-top: 48px;

        .box_h1 {
            position: absolute;
            margin: 0;
            top: 30px;
            
            h1 {
                text-transform: uppercase;
                text-align: center;
                transform: translateY(-50%);
                // background-color: #fff;
                padding: 12px;
                z-index: 2;
                // color: rgb(159, 157, 154);
                color: #333;
                border-radius: 12px;
                border: 3px solid #F3F3F3;
                font-size: 24px;
                font-weight: 700;
    
                @media screen and (min-width: 768px) { 
                    
                    font-size: 24px;
                }
            }
        }

        .qc_other-box {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: 100%;
            height: 100%;
            padding: 12px 6px;
            border-radius: 6px;

            @media screen and (min-width: 768px) { 
                padding: 18px;
            }

            .qc_other-main {
                flex: 1;
             
                margin-top: 12px;
                border-radius: 12px;
                display: flex;
                align-items: center;


                .qc_other-button {
                    flex: 5;
                    width: max-content;
                    background-color: #0B5ED7;
                    color: #fff;
                    border: 0.5px solid #fff;
                    height: max-content;
                    padding: 8px 6px;
                    border-radius: 6px;
                    text-decoration: none;
                    transition: transform 0.2s ease;

                    a {
                        color: #fff;
                    }

                    &:hover {
                        transform: scale(0.95);
                    }
                }

                .qc_other-hide {
                    flex: 1;
                    border-top: 2px dashed #afabab;
                    height: 50%;
                    transform: translateY(50%);
                }

                .qc_other-des {
                    flex: 10;
                    border: 1px solid rgb(180, 171, 171);
                    border-radius: 4px;
                    padding: 8px 6px;
                    background-color: #fff;
                    box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
                }
           
            }
        }
    }
}