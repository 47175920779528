.contact-mobile {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-evenly;
    background-color: #f0f8ff;
    z-index: 10;
    box-shadow: 0 4px 10px 0 #000;

    img {
        width: 30px;
        height: 30px;
    }

    .contact-mobile-phone, .contact-mobile-zalo, .contact-mobile-fb {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        div {
            font-size: 11px;
            font-weight: 500;
        }
        a {
            color: #333;
        }
    }
    .contact-mobile-phone {
        flex: 1;
        position: relative;
            top: -8px;
        .img-phone-box {
            width: 50px;
            height: 50px;
            background-color: #6cb917;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 3px solid #f0f8ff;

            img {
                display: inline-block;
                animation: shake 2.5s ease-in-out 4s infinite;
            }

            @keyframes shake {
                0%, 100% { transform: translate(0, 0) rotate(0); }
                10% { transform: translate(-1px, -1px) rotate(-0.3deg); }
                20% { transform: translate(-2px, 0) rotate(0.3deg); }
                30% { transform: translate(2px, 1px) rotate(0); }
                40% { transform: translate(1px, -0.5px) rotate(0.3deg); }
                50% { transform: translate(-1px, 1px) rotate(-0.3deg); }
                60% { transform: translate(-2px, 0.5px) rotate(0); }
                70% { transform: translate(2px, 0.5px) rotate(-0.3deg); }
                80% { transform: translate(-1px, -0.5px) rotate(0.3deg); }
                90% { transform: translate(1px, 1px) rotate(0); }
              }
        }
    }

    .contact-mobile-zalo {
        flex: 1;
    }

    .contact-mobile-fb {
        flex: 1;
    }
}