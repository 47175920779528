.page-detail {
    display: flex;
    justify-content: center;
    z-index: 1;

    .destop {
        width: 768px;;
    }
}   

.detail {
    background-color: #ffffff;
    color: #333333;
    padding: 20px;
    border: 2px solid #dbe6dc;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 36px 12px;
    position: relative;
    z-index: 1;

    h1 {
        text-transform: uppercase;
        position: absolute;
        top: 0;
        margin: 0;
        padding: 0;
        transform: translateY(-50%);
        background-color: #ffffff;
        font-size: 32px;
        z-index: 2;
    }

    .detail-des {
        .detail-des-box {
            line-height: 1.6;
            p {
                text-align: left;
            }

            .detail-img-box {
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                img {
                    width: 70%;
                    height: 80%;
                    border-radius: 8px;

                    @media screen and (max-width: 767px) { 
                        width: 100%;
                    }
                }

                .img-mota {
                    width: 70%;
                    font-size: 13px;
                    margin-top: 6px;
                }
            }
        }

        .img-conlai-box {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;     
            margin-top: 24px;
            img {
                width: 70%;
                height: 80%;
                border-radius: 8px;

                @media screen and (max-width: 767px) { 
                    width: 100%;
                }
            }

            .img-mota {
                width: 70%;
                font-size: 13px;
                margin-top: 6px;
            }
        }
    }   

    .detail-img {
        margin-top: 12px;
        img {
            width: 60%;
            margin-bottom: 24px;
            border-radius: 8px;
        }
    }

    .marketting {
        padding: 6px;
        background-color: antiquewhite;
        margin-top: 24px;
        line-height: 1.4;
    }
}